import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

// admin
export const getVouchers = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_VOUCHERS,
      payload: null,
    });
    const { data } = await api.getVouchers(formData);
    dispatch({ type: actionsType.GET_VOUCHERS, payload: data });
    dispatch(getCompanies(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getVoucher = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHER,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getVoucher(id);
    dispatch({
      type: actionsType.GET_VOUCHER,
      payload: data.data,
    });
    const voucherNumbers = await api.voucherAnalysis(id);
    dispatch({
      type: actionsType.GET_VOUCHER_NUMBERS,
      payload: voucherNumbers.data.data,
    });
    dispatch(getBranches(history));
    dispatch(getCategories(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteVoucher = (ids, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.deleteVoucher({ ids: ids });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Voucher deleted Successfully" },
    });
    history.push("/admin/vouchers");
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const deleteVoucherTableList =
  (ids, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteVoucher({ ids: ids });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Voucher deleted Successfully" },
      });
      dispatch(getVouchers(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const changeStatusVoucher = (ids, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.voucherChangeStatus({ voucher_ids: ids });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Voucher changed status Successfully" },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.go(0);
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const addNewCode = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("voucher_id", newData.voucher_id);
    formNewData.append("file", newData.file);
    const { data } = await api.addNewCodes(formNewData);
    const duplicates = data.data;
    let text = "new Codes has been added successfully";
    if (duplicates.is_duplicate === 1) {
      text = "new Codes has been added successfully BUT this Codes ([";
      duplicates.duplicate_codes.map((codes) => {
        text += `${codes}, `;
      });
      text += "]) already exists!! ";
    }
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text,
      },
    });
    dispatch(getVoucher(newData.voucher_id, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const addAmount = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.addAmount(newData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "new Amount has been added successfully",
      },
    });
    dispatch(getVoucher(newData.voucher_id, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const assignCategoriesBranches =
  (newData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.assignCategoriesBranches(newData);
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Categories & Branches assigned successfully",
        },
      });
      dispatch(getVoucher(newData.voucher_id, history));
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const createVoucher = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("collection_name", newData.collection_name);
    formNewData.append("title", newData.title);
    formNewData.append("title_ar", newData.title_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    formNewData.append("discount_type", newData.discount_type);
    if (newData.discount_type === 0) {
      formNewData.append("discount_fixed", newData.discount_fixed);
    } else {
      formNewData.append("discount_percentage", newData.discount_percentage);
      formNewData.append("cap", newData.cap);
    }
    formNewData.append("is_single_voucher", newData.is_single_voucher);
    if (newData.is_single_voucher === 0) {
      if (newData.file) {
        formNewData.append("file", newData.file);
      }
    } else {
      formNewData.append("code", newData.code);
      formNewData.append("validity_period", newData.validity_period);
      formNewData.append("available_count", newData.available_count);
    }
    formNewData.append("reminder", newData.reminder);
    formNewData.append(
      "available_per_month_for_user",
      newData.available_per_month_for_user
    );
    formNewData.append("is_active", newData.is_active);
    formNewData.append("logo", newData.logo);
    formNewData.append("logo_ar", newData.logo_ar);
    formNewData.append("logo_dark", newData.logo_dark);
    formNewData.append("logo_ar_dark", newData.logo_ar_dark);
    formNewData.append("discount", newData.discount);
    formNewData.append("discount_ar", newData.discount_ar);
    formNewData.append("discount_dark", newData.discount_dark);
    formNewData.append("discount_ar_dark", newData.discount_ar_dark);
    if (newData.badge !== "") {
      formNewData.append("badge", newData.badge);
      formNewData.append("badge_ar", newData.badge_ar);
      formNewData.append("badge_bg_color", newData.badge_bg_color);
    }
    formNewData.append("company_id", newData.company_id);
    formNewData.append("brand_operator", newData.brand_operator);
    const { data } = await api.createVoucher(formNewData);
    const duplicates = data.data;
    let text = "new Codes has been added successfully";
    if (duplicates.is_duplicate === 1) {
      text = "new Codes has been added successfully BUT this Codes ([";
      duplicates.duplicate_codes.map((codes) => {
        text += `${codes}, `;
      });
      text += "]) already exists!! ";
    }
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });

    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Voucher has been created successfully",
      },
    });
    history.push("/admin/vouchers");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHER_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllCompanies();

    dispatch({
      type: actionsType.GET_VOUCHER_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getBranchesByCategories =
  (catIds, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_VOUCHER_BRANCHES_CATEGORIES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getBranchesByCategories(catIds);
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.GET_VOUCHER_BRANCHES_CATEGORIES,
        payload: data.data,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const getBranches = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHER_BRANCHES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllBranches();
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.GET_VOUCHER_BRANCHES,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const getCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_VOUCHER_CATEGORIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllCategories();

    dispatch({
      type: actionsType.GET_VOUCHER_CATEGORIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getVoucherItems = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_VOUCHER_ITEMS,
      payload: null,
    });
    const { data } = await api.getVoucherItems(formData);
    dispatch({
      type: actionsType.GET_VOUCHER_ITEMS,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteVoucherItems =
  (ids, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteVoucherItems({ ids: ids });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Voucher Items deleted Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch(getVoucherItems(formData, history));
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const deleteNotUsedItems = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.deleteNotUsedItems(formData.voucher_id);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "All Items deleted Successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getVoucherItems(formData, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const deleteExpiredItems = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.deleteExpiredItems(formData.voucher_id);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "All Expired Items deleted Successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getVoucherItems(formData, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateVoucher = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("collection_name", newData.collection_name);
    formNewData.append("title", newData.title);
    formNewData.append("title_ar", newData.title_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    formNewData.append("discount_type", newData.discount_type);
    if (newData.discount_type === 0) {
      formNewData.append("discount_fixed", newData.discount_fixed);
    } else {
      formNewData.append("discount_percentage", newData.discount_percentage);
      formNewData.append("cap", newData.cap);
    }
    formNewData.append("is_single_voucher", newData.is_single_voucher);
    if (newData.is_single_voucher === 0) {
      if (newData.file) {
        formNewData.append("file", newData.file);
      }
    } else {
      formNewData.append("code", newData.code);
      formNewData.append("validity_period", newData.validity_period);
      formNewData.append("available_count", newData.available_count);
    }
    formNewData.append("reminder", newData.reminder);
    formNewData.append(
      "available_per_month_for_user",
      newData.available_per_month_for_user
    );
    formNewData.append("is_active", newData.is_active);
    if (newData.logo) {
      formNewData.append("logo", newData.logo);
    }
    if (newData.logo_ar) {
      formNewData.append("logo_ar", newData.logo_ar);
    }
    if (newData.logo_dark) {
      formNewData.append("logo_dark", newData.logo_dark);
    }
    if (newData.logo_ar_dark) {
      formNewData.append("logo_ar_dark", newData.logo_ar_dark);
    }
    if (newData.discount) {
      formNewData.append("discount", newData.discount);
    }
    if (newData.discount_ar) {
      formNewData.append("discount_ar", newData.discount_ar);
    }
    if (newData.discount_dark) {
      formNewData.append("discount_dark", newData.discount_dark);
    }
    if (newData.discount_ar_dark) {
      formNewData.append("discount_ar_dark", newData.discount_ar_dark);
    }
    formNewData.append("badge", newData.badge);
    formNewData.append("badge_ar", newData.badge_ar);
    if (newData.badge !== "") {
      formNewData.append("badge_bg_color", newData.badge_bg_color);
    } else {
      formNewData.append("badge_bg_color", "");
    }
    formNewData.append("company_id", newData.company_id);
    formNewData.append("brand_operator", newData.brand_operator);
    await api.updateVoucher(formNewData);

    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Voucher has been updated successfully",
      },
    });
    history.push("/admin/vouchers/show/" + newData.id);
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const adminVoucherSearch = (code, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.VOUCHER_IS_SEARCHING,
      payload: { loading: true, message: null },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_ADMIN_VOUCHER,
      payload: null,
    });
    const { data } = await api.voucherSearch(code);
    dispatch({
      type: actionsType.SET_ADMIN_VOUCHER,
      payload: data.data,
    });
    dispatch({
      type: actionsType.VOUCHER_IS_SEARCHING,
      payload: { loading: false, message: null },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    if (error.response.status === 422) {
      dispatch({
        type: actionsType.VOUCHER_IS_SEARCHING,
        payload: { loading: false, message: "No voucher Found" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } else {
      dispatch({
        type: actionsType.VOUCHER_IS_SEARCHING,
        payload: { loading: false, message: null },
      });
      dispatch(errorHandler(error, history));
    }
  }
};

export const adminVoucherRedeem = (code, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.voucherRedeem(code);
    dispatch({
      type: actionsType.SET_ADMIN_VOUCHER,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "voucher used successfully" },
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
