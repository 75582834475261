import React from "react";
import Header from "components/Shared/CutsomHeader/CustomHeader";
import Footer from "components/Shared/CustomFooter/CustomFooter";
import styles from "./homeStyle";
import useClasses from "hooks/useClasses";

export default function Home() {
  const classes = useClasses(styles);
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <h2 className={classes.title}>Etadweer Dashboard</h2>
      </div>
      <Footer />
    </div>
  );
}
